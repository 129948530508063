import React, { useState, useEffect, useContext } from 'react';
import './instrucoes.css';

function Instrucoes(){

    return <div>
      {/* <Navbar/> */}
      <div className="container-fluid titulo">
        <h1>Cadastro de Alunos</h1>

          </div>    
      
    </div>
  }

export default Instrucoes;