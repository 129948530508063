import React,{useContext} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import { AuthContext } from './app/Context/auth.jsx';

/* Paginas */
import Site from './site/site.jsx';
import TermoDeUso from './TermoDeUso';
import Login from './app/Login/login';
import ResetSenha from './app/ResetSenha/resetsenha';
import Home from './app/Home/home';
import Pdf from './app/Pdf/pdf';
import NovoAluno from './app/NovoAluno/novoaluno';
import InicioJogo from './app/InicioJogo/iniciojogo';

import Memoria from './app/Game/memoria';
import Astronauta from './app/Game/astronauta';
import Colheita from './app/Game/colheita';
import Balao from './app/Game/balao';
import Aviao from './app/Game/aviao';
import Trem from './app/Game/trem';
import Montanha from './app/Game/montanha';
import Blocos from './app/Game/blocos';
import Rodada from './app/Game/rodada';
import Tesouro from './app/Game/tesouro';
import Goleada from './app/Game/goleada';
import Impostor from './app/Game/impostor';
import Primavera from './app/Game/primavera';
import Quebracabeca from './app/Game/quebracabeca';  
import Grave from './app/Game/grave';
import Escadinha from './app/Game/escadinha';
import Pizza from './app/Game/pizza';
import Extraterrestre from './app/Game/extraterrestre';
import Alfa from './app/Game/alfa';
import Centopeia from './app/Game/centopeia';
import EstouraBalao from './app/Game/estourabalao';

import EditarAluno from './app/EditarAluno/editaraluno';
import Alunos from './app/Alunos/alunos.jsx';
import Instrucoes from './app/Instrucoes/instrucoes.jsx';

function App(){
    const {logado} = useContext(AuthContext);

    function SecureRoute({...params}){
      if (!logado){
        return <Redirect to="/app" />
      } else {
      return <Route {...params} />
      }
    }
    
    return <BrowserRouter>
    <Switch>
      <Route exact path='/' component={Site} />    
      <Route exact path='/app' component={Login} />    
      <Route path="/TermoDeUso" component={TermoDeUso} />
      <Route exact path='/app/resetsenha' component={ResetSenha} />    
      <SecureRoute exact path='/app/home' component={Home} />        
      <SecureRoute exact path='/app/pdf' component={Pdf} />        
      <SecureRoute exact path='/app/alunos' component={Alunos} />      
      <SecureRoute exact path='/app/instrucoes' component={Instrucoes} />
      <SecureRoute exact path='/app/novoaluno' component={NovoAluno} /> 
      <SecureRoute exact path='/app/iniciojogo/:id' component={InicioJogo} />      
	  <SecureRoute exact path='/app/Memoria' component={Memoria} />
	  <SecureRoute exact path='/app/astronauta' component={Astronauta} />
	  <SecureRoute exact path='/app/colheita' component={Colheita} />
	  <SecureRoute exact path='/app/balao' component={Balao} />
	  <SecureRoute exact path='/app/aviao' component={Aviao} />
	  <SecureRoute exact path='/app/trem' component={Trem} />
	  <SecureRoute exact path='/app/montanha' component={Montanha} />
	  <SecureRoute exact path='/app/blocos' component={Blocos} />
	  <SecureRoute exact path='/app/rodada' component={Rodada} />
	  <SecureRoute exact path='/app/tesouro' component={Tesouro} />
	  <SecureRoute exact path='/app/goleada' component={Goleada} />
	  <SecureRoute exact path='/app/impostor' component={Impostor} />
	  <SecureRoute exact path='/app/primavera' component={Primavera} />
	  <SecureRoute exact path='/app/quebracabeca' component={Quebracabeca} />      
	  <SecureRoute exact path='/app/pizza' component={Pizza} />
    <SecureRoute exact path='/app/extraterrestre' component={Extraterrestre} />  
    <SecureRoute exact path='/app/alfa' component={Alfa} />      
	  <SecureRoute exact path='/app/grave' component={Grave} />    
	  <SecureRoute exact path='/app/escadinha' component={Escadinha} />    
    <SecureRoute exact path='/app/centopeia' component={Centopeia} />    
    <SecureRoute exact path='/app/estourabalao' component={EstouraBalao} />    
	<SecureRoute exact path='/app/editaraluno/:id' component={EditarAluno} />
      </Switch>
    </BrowserRouter>;
  }
 
export default App;