import React from 'react';
import './precos.css';

function Precos() {
    return (
      <section id="preco">

      <div className="row text-center">
        <h1>Planos e Valores</h1>
      </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="card mb-4 shadow-sm">
                <div className="card-body d-flex flex-column justify-content-center align-items-center custom-price-box">
                  <h3 className="card-title pricing-card-title">R$59,90 <small className="text-muted">/ mês</small></h3>
                  <ul className="list-unstyled mt-3 mb-4" style={{ listStyleType: "disc" }}>
                    <li>Jogos digitais musicais</li>
                    <li>Novos jogos digitais regularmente</li>
                    <li>Suporte via Whatsapp</li>
                    <li>Grupo exclusivo Whatsapp</li>
                  </ul>
                  <a href="https://pay.hotmart.com/E86970786G" className="btn btn-lg btn-block buttonContratar">Contratar</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mb-4 shadow-sm">
                <div className="card-body d-flex flex-column justify-content-center align-items-center custom-price-box">
                  <h3 className="card-title pricing-card-title">R$49,90/mês</h3>
                  <h3 className="card-title pricing-card-title"><small className="text-muted">No plano anual</small></h3>
                  <ul className="list-unstyled mt-3 mb-4" style={{ listStyleType: "disc" }}>
                    <li>Jogos digitais musicais</li>
                    <li>Atividades, Ebooks e Jogos em PDF prontos para imprimir</li>
                    <li>Novos materiais regularmente</li>
                    <li>Suporte via Whatsapp</li>
                    <li>Desconto na loja Ana Soares</li>
                    <li>Grupo exclusivo WhatsApp</li>
                  </ul>
                  <a href="https://pay.hotmart.com/U86827501C" className="btn btn-lg btn-block buttonContratar">Contratar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>      
    );
}

export default Precos;
