import React from 'react';
import Navbar from '../Components/Navbar/navbar';
import './pdf.css';
import SearchPdfList from '../Components/SearchPdfList';
import Footer from '../../site/Components/footer';
import Pdfs from "../json/pdfs.json";

function Pdf(){

    return <div>
      <Navbar/>
      <div className="container-fluid titulo">        

         <SearchPdfList/>         

      </div>
      <Footer/>
    </div>
  }

export default Pdf;