import React from 'react';
import './beneficios.css';

function Beneficios() {
  return (
    <section id="beneficios">
      <div className="container">
        <div className="row text-center">
          <h1>Alguns motivos para ser assinante da plataforma Dó Ré Play:</h1>

          <div className="col-md-3 col-6">
            <div className="card-beneficios mb-4 shadow-sm border border-secondary border-3 custom-color">
              <div className="d-flex justify-content-center align-items-center card-img-top">
                <img src="Images/beneficio1.png" alt="Descrição da Imagem 1" className="card-img-small" />
              </div>
              <div className="card-body">
                <div className="text-cards text-center">
                  {/*<p style={{ marginTop: '0px' }}>Abordagens criativas e lúdicas para o ensino de música. o ensino de música.</p>*/}
                  <p style={{ marginTop: '0px' }}>Nossa plataforma oferece uma variedade de jogos musicais, com abordagens criativas e lúdicas.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-6">
            <div className="card-beneficios mb-4 shadow-sm border border-secondary border-3 custom-color">
              <div className="d-flex justify-content-center align-items-center card-img-top">
                <img src="Images/beneficio2.png" alt="Descrição da Imagem 2" className="card-img-small" />
              </div>
              <div className="card-body">
                <div className="text-cards text-center">
                  <p style={{ marginTop: '0px' }}>Plataforma atualizada com frequência, sempre com novos jogos e recursos.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-6">
            <div className="card-beneficios mb-4 shadow-sm border border-secondary border-3 custom-color">
              <div className="d-flex justify-content-center align-items-center card-img-top">
                <img src="Images/beneficio3.png" alt="Descrição da Imagem 3" className="card-img-small" />
              </div>
              <div className="card-body">
                <div className="text-cards text-center">
                  <p style={{ marginTop: '0px' }}>Desenvolver habilidades musicais duradouras, alimentando o amor pela música.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-6">
            <div className="card-beneficios mb-4 shadow-sm border border-secondary border-3 custom-color">
              <div className="d-flex justify-content-center align-items-center card-img-top">
                <img src="Images/beneficio4.png" alt="Descrição da Imagem 4" className="card-img-small" />
              </div>
              <div className="card-body">
                <div className="text-cards text-center">
                  <p style={{ marginTop: '0px' }}>Aprendizado divertido, que facilitam a compreensão e a retenção do conhecimento musical.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Beneficios;
