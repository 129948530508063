import React, { useState } from 'react';
//import './navbar.css';
import Modal from 'react-modal';

function Menu() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Estilos personalizados para o modal (opcional)
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <nav className="navbar fixed-top navbar-expand-md navbar-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/#">
          <img src="Images/logo_doreplay.png" alt="" height="28" />
        </a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              {/*
              <a className="nav-link" aria-current="page" href="#gratuito" onClick={openModal}>
                Conteúdo Gratuito
              </a>
            */}
            </li>
            <li className="nav-item">
              <a style={{ color: 'gray' }} className="nav-link" aria-current="page" href="#preco">Planos e Preços</a>
            </li>
            <li className="nav-item">
              <a style={{ color: 'gray' }} className="nav-link" aria-current="page" href="/app">Login</a>
            </li>
          </ul>
        </div>
      </div>
      
      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Formulário de Conteúdo Gratuito"
      >
        <div>
          <h2>Formulário de Conteúdo Gratuito</h2>
          <form>
            <div className="mb-3">
              <label htmlFor="campo1" className="form-label">Campo 1</label>
              <input type="text" className="form-control" id="campo1" />
            </div>
            <div className="mb-3">
              <label htmlFor="campo2" className="form-label">Campo 2</label>
              <input type="text" className="form-control" id="campo2" />
            </div>
            <div className="mb-3">
              <label htmlFor="campo3" className="form-label">Campo 3</label>
              <input type="text" className="form-control" id="campo3" />
            </div>
          </form>
          <button onClick={closeModal}>Fechar</button>
        </div>
      </Modal>
    </nav>
  );
}

export default Menu;
