import React from 'react';
import styles from "./Card.module.css";

function Card({ id, title, url, cover}) {
    const customTitleStyle = {
        color: 'black',
        maxWidth: '220px',
        overflowWrap: 'break-word',
        //fontFamily: 'League Spartan', // Altere a fonte para a desejada
        //fontFamily: 'Studly', // Altere a fonte para a desejada
        fontSize: '24px', // Altere o tamanho da fonte para o desejado
        fontWeight: 'bold',
        textDecorationColor: 'black', // Defina a cor da linha para preto
    };

    return (
        <section className={styles.card}>
            <a 
                href={`${url}`}
                rel="noreferrer noopener"
                target="_blank">
                <img src={`${cover}`} alt="asdasd" height="150"/>                                       
                <h3 style={customTitleStyle}>{title}</h3>
            </a>
        </section>
    );
}

export default Card;
