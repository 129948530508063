import React from 'react';
import styles from "./JogoList.module.css";
import Card from "../Card";

function JogoList({ jogos, emptyHeading}) {

    return (
        <>
            {/* <h2>teste</h2> */}
            <section className={styles.jogos}>
                { jogos.map((jogo) => <Card id={jogo.id} title={jogo.title} url={jogo.url} cover={jogo.cover} key={jogo.id} /> )}
            </section>
        </>
    )
}

export default JogoList;