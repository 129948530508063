import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from "./SearchPdfList.module.css";
import JogoList from '../JogoListPdf';
import jogos from "../../json/pdfs.json";
import Container from '../Container';
import { AuthContext } from '../../Context/auth';
import { format } from 'date-fns';

function filterJogos(jogos, searchText, perfil, tipoPerfil) {
  searchText = searchText.toLowerCase();
  return jogos.filter((jogo) => {
    const categoria = jogo.category.toLowerCase(); // Converter a categoria para minúsculas
    const titulo = jogo.title.toLowerCase(); // Converter o título para minúsculas

    // Verificar se a categoria ou o título contêm o texto de pesquisa
    return (
      (categoria.includes(searchText) || titulo.includes(searchText))
    );
  });
}


function isDataValida(data) {

  if (data === null) {
    return false;
  }
  const dataAtual = new Date();
  const dataLiberacao = new Date(data);

  // Verifica se a data é válida (não é NaN) e se é menor ou igual à data atual
  return !isNaN(dataLiberacao) && dataLiberacao <= dataAtual;

}

function formatarData(data) {
  if (!data) return ''; // Verifica se a data é válida

  const partesData = data.split('-');
  if (partesData.length !== 3) return ''; // Verifica se a data possui três partes (ano, mês e dia)

  const [ano, mes, dia] = partesData;
  return `${dia}/${mes}/${ano}`;
}

function SearchPdfList() {
  const [searchText, setSearchText] = useState('');
  const { perfil } = useContext(AuthContext);
  const { tipoPerfil } = useContext(AuthContext); 
  const foundJogos = filterJogos(jogos, searchText, perfil, tipoPerfil);
  //const foundJogos = tipoPerfil === "aluno" ? filterJogos(jogos, searchText, perfil, tipoPerfil) : jogos;
  const liberarPdf = isDataValida(perfil);

  console.log("tipoPerfil=" + tipoPerfil);

  const estiloFrase = {
    color: 'red',
    fontWeight: 'bold',
    marginTop: '30px',  // Espaço acima da frase
    marginBottom: '40px' // Espaço abaixo da frase
  };

  let mensagem;
  if (liberarPdf || tipoPerfil=="adm") {
    mensagem = <JogoList jogos={foundJogos} />;
  } else {
    if ((perfil != null)) { //perfil contem data?
      mensagem = (
        <p style={estiloFrase}>
          Bônus!!!!!! "PDF's" serão liberados em {formatarData(perfil)}.
        </p>
      );
    } else {
      mensagem = (
        <p style={estiloFrase}>
          PDF's não disponíveis para este perfil de usuário.
        </p>
      );
    }
  }

  return (
    <Container>
      <section className={styles.container}>
        <div className="row">
          <div className="col-8">
            <div className="input-group mb-3">
              <input onChange={(e) => setSearchText(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" />
              <button onClick={() => setSearchText(searchText)} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
            </div>
          </div>
          <div className="col-4">
            {tipoPerfil === "adm" && (
              <Link to='/app/alunos' className="btn btn-primary btn-cli" type="button">
                <i className="fas fa-plus"></i> Professor
              </Link>
            )}
            {tipoPerfil === "prof" && (
              <Link to='/app/alunos' className="btn btn-primary btn-cli" type="button">
                <i className="fas fa-plus"></i> Aluno
              </Link>
            )}
        </div>
        </div>
        {mensagem}
      </section>
    </Container>
  )
}

export default SearchPdfList;
