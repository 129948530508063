import React from 'react';
import './imagens.css';
import { useMediaQuery } from 'react-responsive';

function Oqueeh() {
  // Use useMediaQuery para detectar o tamanho da tela
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <section id="banner">
      <div className="d-flex justify-content-center align-items-center">
        <div className="position-relative"> {/* Envolve a imagem e o texto */}
          <img
            src="Images/beneficios2.jpeg"
            alt="ANA SOARES"
            className="mx-auto img-fluid"
            style={{ maxWidth: '100%', minWidth: '80%' }}
          />
          <div className="position-absolute top-50 start-0 translate-middle-y text-center w-50">
            <h1 style={{ fontSize: isMobile ? '1.2rem' : '' }}>Com nossa plataforma, você não precisa mais gastar seu tempo precioso criando materiais do zero</h1>
            <h4 style={{ fontSize: isMobile ? '1rem' : '' }}>Isso significa que você economizará horas preciosas de preparação, permitindo que se concentre no que mais importa: ensinar música de forma inspiradora.</h4>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Oqueeh;
