import React, { useEffect } from 'react';

function TermoDeUso() {
  useEffect(() => {
    // Quando o componente é montado, rola a página para o topo
    window.scrollTo(0, 0);
  }, []);
  
  const estiloComMargens = {
    margin: '20px 20px', // 20px na parte superior e inferior, 0 nas laterais
  };

  return (
    <div style={estiloComMargens}>
      <h1>Política de Privacidade, Termos e Condições</h1>
      <p>Estes documentos, que incluem os Termos de Uso, Política de Privacidade e Termo de Aceitação de Cookies, representam o contrato jurídico que regula a relação entre você (usuário) e o Portal Dó Ré Play Jogos Musicais em relação ao seu acesso e utilização do site oficial.</p>
      <h2>Política de Privacidade:</h2>
      <p>Nós respeitamos a privacidade dos nossos usuários. Esta política descreve como coletamos, usamos e protegemos informações pessoais.</p>
      <p>Coletamos informações pessoais, como nome, endereço de e-mail, e informações de pagamento, apenas quando necessário para o funcionamento da plataforma.</p>
      <p>Utilizamos informações pessoais para fornecer acesso à plataforma, personalizar conteúdo, processar pagamentos e melhorar nossos serviços.</p>
      <p>Mantemos medidas de segurança para proteger informações pessoais contra acesso não autorizado ou divulgação.</p>
      <p>Utilizamos cookies para melhorar a experiência do usuário, mas você pode ajustar as configurações do navegador para recusar cookies, se preferir.</p>
      <p>Podemos compartilhar informações com terceiros apenas quando estritamente necessário para fornecer serviços ou cumprir a lei.</p>
      <p>Reservamo-nos o direito de atualizar esta política periodicamente.</p>
      <h2>Termos e Condições de Uso:</h2>
      <p>O conteúdo deste site não pode ser copiado, reproduzido, publicado, modificado, compartilhado por links ou por telecomunicação sem permissão dos responsáveis da Plataforma Dó Ré Play.</p>
      <p>Os usuários deste site têm permissão para usá-lo exclusivamente para fins pessoais, excluindo qualquer uso comercial ou governamental.</p>
      <p>Ao usar nossa plataforma, você concorda em cumprir nossos termos e condições.</p>
      <p>Você é responsável por manter a confidencialidade de sua conta e não compartilhar credenciais de login.</p>
      <p>Todo o conteúdo disponível na plataforma é protegido por direitos autorais. Não é permitido o uso não autorizado.</p>
      <p>Reservamo-nos o direito de encerrar contas que violem nossos termos.</p>
      <p>Dessa forma, caso o Usuário discorde de quaisquer disposições, deverá imediatamente se abster de acessar o Site.</p>
    </div>
  );
}

export default TermoDeUso;
