import React from 'react';
import './imagens.css';

function Banner0() {
  return (
    <section id="banner-0">
      <div className="d-flex justify-content-center align-items-center">
        <img
          src="Images/principal0.webp"
          alt="ANA SOARES"
          className="mx-auto img-fluid"
          style={{ maxWidth: '100%', minWidth: '80%' }}
        />
      </div>
    </section>
  );
}

// Pré-carregamento da imagem no HTML
document.head.innerHTML += `
<link rel="preload" href="Images/principal0.webp" as="image" />
`;

export default Banner0;
