import React from 'react';
import './imagens.css';

function Bonus() {
  return (
    <section id="banner-azul">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
            <img src="Images/bonus.jpeg" alt="ANA SOARES" className="banner-image" />
          </div>
          <div className="col-lg-6">
            <h1>Bônus Exclusivo Assinantes Anuais:</h1>
            <h4>acesso a uma impressionante coleção de mais de 36 PDFs de jogos e atividades musicais.</h4>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Bonus;
