import firebase from 'firebase/app';
//import firebase from 'firebase';

const firebaseConfig = {
  /*
    apiKey: "AIzaSyBAasehEppfORce7VR-UX_fcsAwQy8jtDo",
    authDomain: "crm-7b1fa.firebaseapp.com",
    projectId: "crm-7b1fa",
    storageBucket: "crm-7b1fa.appspot.com",
    messagingSenderId: "709155864686",
    appId: "1:709155864686:web:7564db6026791727c0667f"
    */
      apiKey: "AIzaSyDMzfIr7zWXRAH9kBTIDfWsjSIjWIJVScE",
      authDomain: "anasoares-8a36b.firebaseapp.com",
      projectId: "anasoares-8a36b",
      storageBucket: "anasoares-8a36b.appspot.com",
      messagingSenderId: "522605475798",
      appId: "1:522605475798:web:accb540e8b047b2d3d5677"
  };
  
  // Initialize Firebase
export default firebase.initializeApp(firebaseConfig);
