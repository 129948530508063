import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Footer() {
  var ano = new Date().getFullYear();

  return (
    <section id="footer" className="text-center">
      <div className="social">
        <ul className="list-unstyled list-inline social-icons">
          <li className="list-inline-item">
            <a href="https://www.instagram.com/doreplayanasoares/">
              <i className="fa fa-instagram fa-2x"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="email"></div>
      <div className="credit">
        <p>
          Dó Ré Play © Copyright {ano} Todos os direitos reservados{' '}
          <Link to="/TermoDeUso" className="nav-link text-black" aria-current="page">
            Política de Privacidade e Termos de Uso
          </Link>
        </p>
      </div>

    </section>
  );
}

export default Footer;
