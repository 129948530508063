import React, { useState,useContext, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import './editaraluno.css';
import firebase from '../Config/firebase';
import 'firebase/firestore';
import jogos from '../json/jogos.json';
import { AuthContext } from '../Context/auth';

function EditarAluno(props) {
  const [perfilBefore, setPerfilBefore] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [dataSelecionada, setDataSelecionada] = useState(''); 
  const [senhaBefore, setSenhaBefore] = useState('');
  const [displayName, setDisplayName] = useState(''); 
  const [mensagem, setMensagem] = useState('');
  const [sucessoSenha, setSucessoSenha] = useState('S');
  const [sucessoPerfil, setSucessoPerfil] = useState('N');
  const [jogosSelecionados, setJogosSelecionados] = useState([]);
  const { tipoPerfil } = useContext(AuthContext);
  const db = firebase.firestore();
  let sufixo = "";

  const dataMaxima = new Date();
  dataMaxima.setDate(dataMaxima.getDate() + 30);
  const dataMaximaFormatada = dataMaxima.toISOString().split('T')[0];

  useEffect(() => {
    firebase.firestore().collection('alunos').doc(props.match.params.id).get().then((resultado) => {
      setEmail(resultado.data().email);
      setSenhaBefore(resultado.data().senha);
      setDataSelecionada(resultado.data().expirationDate);
      setPerfilBefore(resultado.data().perfil);
      setJogosSelecionados(resultado.data().perfil.split(','));
      setSenha(resultado.data().senha); // Adicionamos isso para carregar a senha corretamente
      setDisplayName(resultado.data().perfil);
    });
  }, [props.match.params.id]);

  function handleSelecionarJogo(jogoId) {
    if (jogosSelecionados.includes(jogoId)) {
      setJogosSelecionados(jogosSelecionados.filter(id => id !== jogoId));
    } else {
      if (tipoPerfil === 'adm' || jogosSelecionados.length < 5) {
        setJogosSelecionados([...jogosSelecionados, jogoId]);
      } else {
        setMensagem('Selecione no máximo 5 jogos');
        setTimeout(() => {
          setMensagem('');
        }, 2000);
      }
    }
  }

  function AlterarCliente() {
    if (!email.includes('@')) {
      sufixo = '@as.com';
    }

    if (email.length === 0) {
      setMensagem('Informe o e-mail');
    } else {
      firebase.auth().signInWithEmailAndPassword(email+sufixo, senhaBefore)
        .then(() => {
          const userInFirebaseAuth = firebase.auth().currentUser;

          if (senhaBefore !== senha) {
            userInFirebaseAuth.updatePassword(senha).then(() => {
              console.log("sucesso update senha");
              updateSenhaAluno();
            }).catch((error) => {
              console.log("erro update senha: " + error);
              setMensagem('erro update senha: ' + error);
              setSucessoSenha('N');
            });
          }

          //if (perfilBefore !== jogosSelecionados.join(',')) {
            console.log("vai atualizar perfil");
            if (sucessoSenha === 'S') {
              console.log("mensagem=[" + mensagem + "]");
              updatePerfilAluno();
              console.log("Atualiza displayName="+displayName);
              updateDisplayNameAluno();
            } else {
              console.log("a senha está diferente de S, sucessoSenha=[" + sucessoSenha + "]");
            }
          //} else {
            //setSucessoPerfil('S');
          //}

        }).catch((error) => {
          console.log("error=" + error);
        });
    }
  }

  function updateSenhaAluno() {
    db.collection('alunos').doc(props.match.params.id).update({
      senha: senha
    }).then(() => {
      setMensagem('');
      setSucessoSenha('S');
    }).catch((erro) => {
      setMensagem(erro);
      setSucessoSenha('N');
    });
  }

  function updatePerfilAluno() {
    db.collection('alunos').doc(props.match.params.id).update({
      perfil: jogosSelecionados.join(','),
      expirationDate: tipoPerfil === 'prof' ? `${dataSelecionada}`:''
    }).then(() => {
      setMensagem('');
      setSucessoPerfil('S');
    }).catch((erro) => {
      setMensagem(erro);
      setSucessoPerfil('N');
    });
  }

  function updateDisplayNameAluno() {
    const userInFirebaseAuth = firebase.auth().currentUser;

    userInFirebaseAuth.updateProfile({
      displayName: jogosSelecionados.join(','),
      photoURL: tipoPerfil === 'adm' ? 'prof' : 'aluno|'+`${dataSelecionada}`
    }).then(() => {
      console.log("Sucesso na atualização do displayName="+jogosSelecionados.join(','));
      //updatePerfilAluno(); // Chamada para atualizar o perfil no Firestore
    }).catch((error) => {
      console.log("Erro na atualização do displayName: " + error);
    });
  }

  return (
    <div>
      <Navbar />
      <div className="container-fluid titulo">
        <div className="offset-lg-3 col-lg-6">
        <h1 className="h3 mb-3 fw-normal">
          {tipoPerfil === 'adm' ? 'Alterar Professor' : 'Alterar Aluno'}
        </h1>    
          <form>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">{tipoPerfil === 'adm' ? 'E-mail' : 'Usuário'}</label>
              <input value={email} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">Senha</label>
              <input onChange={(e) => setSenha(e.target.value)} value={senha} type="text" className="form-control" id="exampleInputPassword1" />
            </div>
            {tipoPerfil === 'prof' && (
            <div className="mb-3">
              <label htmlFor="exampleInputDate" className="form-label">Data Expiração</label>
              <input onChange={(e) => setDataSelecionada(e.target.value)} 
                value={dataSelecionada}
                max={dataMaximaFormatada} 
                type="date" className="form-control" id="exampleInputDate" />              
            </div>
            )}

            {tipoPerfil !== 'adm' && (
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Jogos Permitidos:</label>
              {jogos.map(jogo => (
                // Verifica se a categoria do jogo não é "teste" antes de renderizá-lo
                jogo.category !== 'teste' && (
                  <div className="form-check" key={jogo.id}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`jogo-${jogo.id}`}
                      checked={jogosSelecionados.includes(jogo.id)}
                      onChange={() => handleSelecionarJogo(jogo.id)}
                    />
                    <label className="form-check-label" htmlFor={`jogo-${jogo.id}`}>
                      {jogo.title}
                    </label>
                  </div>
                )
              ))}

            </div>
            )}

            <div className="text-center">
              <Link to="/app/alunos" className="btn btn-outline-primary btn-acao">Voltar</Link>
              <button onClick={AlterarCliente} type="button" className="btn btn-primary btn-acao">Salvar</button>
            </div>

            {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
            {sucessoSenha === 'S' && sucessoPerfil === 'S' ? <Redirect to='/app/alunos' /> : null}
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditarAluno;
