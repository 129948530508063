import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './navbar.css';
import { AuthContext } from '../../Context/auth';

function Navbar() {
  const { setLogado, tipoPerfil, perfil } = useContext(AuthContext);
  const location = useLocation(); // Obtém a localização atual da rota

  //console.log("menu tipoPerfil=" + tipoPerfil);

  function Logout() {
    setLogado(false);
    localStorage.removeItem("logado");
  }

  function isDataValida(data) {
    if (data === null && tipoPerfil !== "adm") {
      return false;
    }
    const dataInformada = new Date(data);

    return !isNaN(dataInformada);
  }

  return (
    <nav className="navbar fixed-top navbar-expand-md navbar-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/#">
          <img src="/Images/logo_doreplay.png" alt="" height="28" />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">
            {/* Outras opções de menu */}
            {tipoPerfil === "adm" && (
              <li className="nav-item">
                <Link to="/app/alunos" style={{ color: 'gray' }} className="nav-link text-black" aria-current="page">
                  Professores
                </Link>
              </li>
            )}
            {/* Verifique se a URL atual é '/app/pdf' e mostre 'Jogos Digitais' em vez de 'PDFs' */}

            {tipoPerfil !== "aluno" && (
              location.pathname === "/app/pdf" ? (
                <li className="nav-item">
                  <Link to="/app/home" style={{ color: 'gray' }} className="nav-link text-black" aria-current="page">
                    Jogos Digitais
                  </Link>
                </li>
              ) : (
                // Se não estiver em '/app/pdf', mostre 'PDFs' normalmente
                isDataValida(perfil) && (
                  <li className="nav-item">
                    <Link to="/app/pdf" style={{ color: 'gray' }} className="nav-link text-black" aria-current="page">
                      PDFs
                    </Link>
                  </li>
                )
              )
            )}

            {tipoPerfil === "prof" && (
              <li className="nav-item">
                <Link to="/app/alunos" style={{ color: 'gray' }} className="nav-link text-black" aria-current="page">
                  Alunos
                </Link>
              </li>
            )}
            <li className="nav-item">
              <a href="/app" style={{ color: 'gray' }} onClick={Logout} className="nav-link logout text-black" aria-current="page">
                Sair
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
