import React, { useState, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './login.css';
import { AuthContext } from '../Context/auth';
import firebase from '../Config/firebase';
import 'firebase/auth';

function Login() {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [sucesso, setSucesso] = useState('');
  const { setLogado } = useContext(AuthContext);
  const { setPerfil } = useContext(AuthContext);
  const { setTipoPerfil } = useContext(AuthContext);
  const { setUid } = useContext(AuthContext);
  const [mensagem, setMensagem] = useState('');

  function LoginUsuario() {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, senha)
      .then(function (firebaseUser) {
        let tipoPerfil = firebase.auth().currentUser.photoURL;
        let dataExpiracao = null;
  
        if (tipoPerfil && tipoPerfil.startsWith("aluno") && tipoPerfil.includes("|")) {
          const partes = tipoPerfil.split("|");
          if (partes.length === 2) {
            //console.log('dataExpiracaoOriginallll='+partes[1]);
            dataExpiracao = new Date(partes[1]);
            dataExpiracao.setDate(dataExpiracao.getDate() + 1);
            dataExpiracao.setHours(23, 59, 59, 999); // Define a hora para 23:59:59.999
            tipoPerfil = partes[0];
          }
        } else {
          dataExpiracao = new Date();
        }
  
        //console.log('dataExpiracao1='+dataExpiracao);
        
        // Verifique se a data de expiração é maior ou igual à data atual
        if ((tipoPerfil && !tipoPerfil.startsWith("aluno")) || (dataExpiracao && dataExpiracao >= new Date())) {
          localStorage.setItem('perfil', firebase.auth().currentUser.displayName);
          localStorage.setItem('tipoPerfil', tipoPerfil);
          localStorage.setItem('uidProf', firebase.auth().currentUser.uid);
          localStorage.setItem('logado', 'S');
          //console.log('perfilFinal='+firebase.auth().currentUser.displayName);
          //console.log('tipoPerfilFinal='+tipoPerfil);
          setLogado(true);
          setPerfil(firebase.auth().currentUser.displayName);
          setTipoPerfil(tipoPerfil);
          setUid(firebase.auth().currentUser.uid);
          setSucesso('S');
        } else {
          // Data de expiração expirada, exibir mensagem e não autenticar
          setSucesso('N');
          setLogado(false);
          setMensagem('Usuário expirado. Entre em contato com o seu professor.');
        }
      })
      .catch(function (error) {
        localStorage.setItem('logado', 'N');
        setLogado(false);
        setSucesso('N');
        setMensagem('Usuário ou senha inválida.');      
      });
  }

  function alterarEmail(event) {
    let novoEmail = event.target.value;
  
    // Verifica se o valor inserido não contém "@"
    if (!novoEmail.includes('@')) {
      novoEmail += '@as.com';
    }
  
    setEmail(novoEmail);
  }

  function alterarSenha(event) {
    setSenha(event.target.value);
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center text-center form-container">
      <form className="form-signin bg-blue-light rounded-form"> {/* Adicionando a classe bg-white */}
        <img className="mb-4 logo-img" src="Images/logo_new.png" alt="" />
        <div className="form-floating">
          <input
            onChange={alterarEmail}
            type="email"
            className="form-control"
            id="floatingInput"
            placeholder="E-mail"
          />
          <label htmlFor="floatingInput">E-mail ou Usuário</label>
        </div>

        <div className="form-floating">
          <input
            onChange={alterarSenha}
            type="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Senha"
          />
          <label htmlFor="floatingPassword">Senha</label>
        </div>

        <button
          onClick={LoginUsuario}
          className="w-100 btn btn-lg btn-primary mt-3"
          type="button"
        >
          Acessar
        </button>

        {sucesso === 'N' ? (
          <div className="alert alert-danger mt-2" role="alert">
            {mensagem}
          </div>
        ) : null}
        {sucesso === 'S' ? <Redirect to="/app/home" /> : null}

        <div className="login-links mt-2">
          <Link to="/app/resetsenha" className="mx-3">
            Esqueci minha senha
          </Link>
        </div>
      </form>
      <div className="credit">
        <p>Dó Ré Play © Copyright 2023 Todos os direitos reservados</p>
      </div>
    </div>
  );
}

export default Login;
