import React from 'react';
import './imagens.css';

function Banner2() {
  return (
    <section id="banner-azul">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-md-last"> {/* A ordem da coluna muda para "última" em telas menores */}
            <h1>Acesso a uma ampla variedade de jogos digitais musicais lúdicos:</h1>
            <h4>Estimule a criatividade e o interesse dos alunos com jogos interativos projetados para tornar o aprendizado musical divertido e envolvente.</h4>
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center"> {/* Centralize a imagem na sessão */}
            <img src="Images/principal2.png" alt="ANA SOARES" className="banner-image"/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner2;
