import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from "./SearchJogoList.module.css";
import JogoList from '../JogoList';
import jogos from "../../json/jogos.json";
import Container from '../Container';
import { AuthContext } from '../../Context/auth';

/*
function filterJogos(jogos, searchText, perfil, tipoPerfil) {
  searchText = searchText.toLowerCase(); // Converter o texto de pesquisa para minúsculas

  return jogos.filter((jogo) => {
    const titulo = jogo.title.toLowerCase(); // Converter o título para minúsculas
    const categoria = jogo.category.toLowerCase(); // Converter a categoria para minúsculas

    // Verificar se o título ou a categoria contêm o texto de pesquisa
    return (
      (tipoPerfil !== "aluno" || isValidGame(jogo, perfil)) &&
      (categoria.includes(searchText) || titulo.includes(searchText))
    );
  });
}
*/

function filterJogos(jogos, searchText, perfil, tipoPerfil) {
  searchText = searchText.toLowerCase(); // Converter o texto de pesquisa para minúsculas

  return jogos.filter((jogo) => {
    const titulo = jogo.title.toLowerCase(); // Converter o título para minúsculas
    const categoria = jogo.category.toLowerCase(); // Converter a categoria para minúsculas
    const isAdmAndTesteCategory = tipoPerfil === "adm" && categoria === "teste";

    // Verificar se o título ou a categoria contêm o texto de pesquisa
    // e se a categoria não é "teste"
    return (
      (tipoPerfil !== "aluno" || isValidGame(jogo, perfil)) &&
      ((categoria !== "teste") || isAdmAndTesteCategory) && // Esta linha impede que jogos com categoria "teste" sejam mostrados
      (categoria.includes(searchText) || titulo.includes(searchText))
    );
  });
}

function isValidGame(jogo, perfil) {
  //console.log("jogo="+jogo+" perfil="+perfil);
  if (perfil === null || perfil === "") {
    return false;
  }

  var permittedGames = perfil.split(",").map(item => item.trim()); // Remove espaços extras
  var idGame = jogo.id.toString(); // Certificar-se de que é uma string
  //console.log("perfil=" + perfil + " permittedGames=" + JSON.stringify(permittedGames));

  if (permittedGames.indexOf(idGame) !== -1) {
    //console.log("true " + idGame);
    return true;
  } else {
    //console.log("false " + idGame);
    return false;
  }
}

function SearchJogoList() {
  const [searchText, setSearchText] = useState('');
  const { perfil } = useContext(AuthContext);
  const { tipoPerfil } = useContext(AuthContext); 
  const foundJogos = filterJogos(jogos, searchText, perfil, tipoPerfil);
  //const foundJogos = tipoPerfil === "aluno" ? filterJogos(jogos, searchText, perfil, tipoPerfil) : jogos;

  //console.log("SearchJogoList tipoPerfil=(" + tipoPerfil+")");
  //console.log("SearchJogoList perfil=(" + perfil+")");
  //console.log("SearchJogoList jogos=(" + jogos+")");
  //console.log("SearchJogoList foundJogos=(" + foundJogos+")");

  return (
    <Container>
      <section className={styles.container}>
        <div className="row">
          <div className="col-8">
            <div className="input-group mb-3">
              <input onChange={(e) => setSearchText(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" />
              <button onClick={() => setSearchText(searchText)} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
            </div>
          </div>
          <div className="col-4">
            {tipoPerfil === "adm" && (
              <Link to='/app/alunos' className="btn btn-primary btn-cli" type="button">
                <i className="fas fa-plus"></i> Professor
              </Link>
            )}
            {tipoPerfil === "prof" && (
              <Link to='/app/alunos' className="btn btn-primary btn-cli" type="button">
                <i className="fas fa-plus"></i> Aluno
              </Link>
            )}
        </div>
        </div>
        <JogoList jogos={foundJogos} />
      </section>
    </Container>
  )
}

export default SearchJogoList;
