import React from 'react';
import './imagens.css';
import { useMediaQuery } from 'react-responsive';

function Quemsou() {
  // Use useMediaQuery para detectar o tamanho da tela
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <section id="banner">
      <div className="position-relative">
        <img
          src="Images/quemsoueu.jpeg"
          alt="ANA SOARES"
          className="mx-auto img-fluid"
          style={{ maxWidth: '100%', minWidth: '80%' }}
        />
        <div className="position-absolute top-50 start-50 translate-middle text-center w-50">
          <h4 style={{ fontSize: isMobile ? '0.7rem' : '1.2rem' }}>Sou uma professora pedagoga apaixonada por música e educação musical. Minha jornada inclui uma formação em órgão popular e especializações em atividades lúdicas musicais e cursos de musicalização infantil.</h4>
          <h4 style={{ fontSize: isMobile ? '0.7rem' : '1.2rem' }}>Experiência como regente em turmas de Educação Infantil, anos iniciais do Ensino Fundamental e Alfabetização de Jovens e Adultos.</h4>
          <h4 style={{ fontSize: isMobile ? '0.7rem' : '1.2rem' }}>Minha missão é proporcionar experiências educacionais enriquecedoras que despertem o amor pela música e pela aprendizagem nas crianças, jovens e adultos.
            É um privilégio compartilhar minha expertise e inspirar as gerações mais jovens a explorar o mundo encantador da música por meio do lúdico.</h4>
        </div>
      </div>
    </section>
  );
}

export default Quemsou;
