import React from 'react';
import './imagens.css';

function Acesso(){
    return (
      <section id="banner-azul">
        <div className="container">
          <div className="row">
          <div className="col-lg-6 d-flex justify-content-center align-items-center"> {/* Centralize a imagem na sessão */}
              <img src="Images/acesso.png" alt="ANA SOARES" className="banner-image"/>
            </div>
            <div className="col-lg-6">
              <h1>Como ter acesso à plataforma?</h1>
              <h4>É bem fácil! Escolha o plano que mais combina com você, assine e faça o pagamento. Após a confirmação bem-sucedida do pagamento, você vai receber um e-mail com suas informações de acesso.</h4>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Acesso;
