import React from 'react';
import Navbar from '../Components/Navbar/navbar';
import './home.css';
import SearchJogoList from '../Components/SearchJogoList';
import Footer from '../../site/Components/footer';

function Home(){

    return <div>
      <Navbar/>
      <div className="container-fluid titulo">        

         <SearchJogoList/>

      </div>
      <Footer/>
    </div>
  }

export default Home;