import React, { useState, useContext, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './novoaluno.css';
import firebase from '../Config/firebase';
import 'firebase/auth';
import { AuthContext } from '../Context/auth';
import jogos from '../json/jogos.json';

function NovoAluno() {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');
  const [jogosSelecionados, setJogosSelecionados] = useState([]);
  const [dataSelecionada, setDataSelecionada] = useState(''); 
  const [liberarPDF, setLiberarPDF] = useState(false); // Checkbox para liberar PDF
  const { tipoPerfil } = useContext(AuthContext);
  const { uidProf } = useContext(AuthContext);

  const dataMaxima = new Date();
  dataMaxima.setDate(dataMaxima.getDate() + 30);
  const dataMaximaFormatada = dataMaxima.toISOString().split('T')[0];

  // Variáveis para a data
  const dataPadrao = new Date();
  if (tipoPerfil==='adm') {
    dataPadrao.setDate(dataPadrao.getDate() + 7); // D+7 dias a partir da data atual
  } else {
    dataPadrao.setDate(dataPadrao.getDate() + 30); // D+7 dias a partir da data atual
  }
  const dataPadraoFormatada = dataPadrao.toISOString().split('T')[0];

  function handleEmailChange(e) {
    const value = e.target.value;

    // Verifique se o tipoPerfil é diferente de "adm"
    if (tipoPerfil !== 'adm') {
      // Remova caracteres especiais, exceto o "@" usando uma expressão regular
      const cleanedValue = value.replace(/[^a-zA-Z0-9-_]+/g, '');

      setEmail(cleanedValue);
    } else {
      // Se for "adm", não faça a validação e permita qualquer entrada
      setEmail(value);
    }
  }

  function handleSelecionarJogo(jogoId) {
    if (jogosSelecionados.includes(jogoId)) {
      setJogosSelecionados(jogosSelecionados.filter(id => id !== jogoId));
    } else {
      if (tipoPerfil === 'adm' || jogosSelecionados.length < 5) {
        setJogosSelecionados([...jogosSelecionados, jogoId]);
      } else {
        setMensagem('Selecione no máximo 5 jogos');
        setTimeout(() => {
          setMensagem('');
        }, 2000);
      }
    }
  }

  function cadastrarUsuario() {
    setMensagem('');

    if (!email || !senha) {
      setMensagem('Informe todos os campos');
      return;
    }

    let permissaoString = jogosSelecionados.join(',');

    if (tipoPerfil === 'adm' && liberarPDF) {
      // Se for um administrador e liberar o PDF, adicione a data selecionada à permissão
      permissaoString += `${dataSelecionada}`;
    }

    // Adicione o sufixo ao email se tipoPerfil diferente de "adm"
    const emailSalvar = tipoPerfil !== 'adm' ? email + '@as.com' : email;

    firebase.auth().createUserWithEmailAndPassword(emailSalvar, senha).then(resultado => {
      firebase.auth().currentUser.updateProfile({
        displayName: permissaoString,
        photoURL: tipoPerfil === 'adm' ? 'prof' : 'aluno|'+`${dataSelecionada}`
      }).then(() => {
        firebase.firestore().collection('alunos').add({
          perfil: permissaoString,
          email: email,
          senha: senha,
          professor: uidProf,
          uidaluno: firebase.auth().currentUser.uid,
          expirationDate: tipoPerfil === 'prof' ? `${dataSelecionada}`:''
        }).then(() => {
          setSucesso('S');
        }).catch((error) => {
          console.error('Erro ao adicionar ' + (tipoPerfil === 'adm' ? 'professor' : 'aluno') + ':', error);
          setSucesso('N');
        });
      }).catch((error) => {
        console.error('Erro ao atualizar perfil do usuário:', error);
        setSucesso('N');
      });
    }).catch(error => {
      console.error('Erro ao criar usuário:', error);
      setSucesso('N');
      if (error.message === 'Password should be at least 6 characters') {
        setMensagem('A senha deve ter pelo menos 6 caracteres');
      } else if (error.message === 'The email address is badly formatted.') {
        setMensagem('O email não é válido');
      }
      else if (error.message === 'The email address is already in use by another account.') {
        setMensagem('Esse usuário "'+email+'" já está em uso por outra conta');
      }
      else {
        setMensagem('Erro ao criar conta: ' + error.message);
      }
    });
  }

  useEffect(() => {
    // Defina a data padrão quando o tipo de perfil for 'adm' e o checkbox estiver marcado
    //if (tipoPerfil === 'adm' && liberarPDF) {
      setDataSelecionada(dataPadraoFormatada);
    //}
  }, [tipoPerfil, liberarPDF]);

  // Restante do código...

  return (
    <div className="d-flex align-items-center justify-content-center form-container">
      <form className="form-signin">
        <h1 className="h3 mb-3 fw-normal">
          {tipoPerfil === 'adm' ? 'Novo Professor' : 'Novo Aluno'}
        </h1>

        <div className="form-floating">
          <input
            type="email"
            className="form-control"
            id="floatingInput"
            placeholder="E-mail"
            value={email}
            onChange={handleEmailChange} // Adicione o manipulador de eventos aqui
          />
          <label htmlFor="floatingInput">{tipoPerfil === 'adm' ? 'E-mail' : 'Usuário'}</label>
        </div>

        <div className="form-floating">
          <input
            type="text"
            className="form-control"
            id="floatingPassword"
            placeholder="Senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
          <label htmlFor="floatingPassword">Senha</label>
        </div>

        {tipoPerfil === 'prof' && (
          <div style={{ marginTop: '20px' }} className="form-floating">
            <input
              type="date"
              className="form-control"
              id="floatingDate"
              placeholder="Data"
              value={dataSelecionada}
              onChange={(e) => setDataSelecionada(e.target.value)}
              max={dataMaximaFormatada} // Defina o valor máximo aqui
              defaultValue={dataPadraoFormatada}
            />
            <label htmlFor="floatingDate">Usuário expira em:</label>
          </div>
        )}

        {tipoPerfil !== 'adm' && (
  <div style={{ marginTop: '20px' }} className="form-group">
    <h2 className="h3 mb-3 fw-normal">
      <label>Jogos Permitidos:</label>
    </h2>
    {jogos.map(jogo => (
      jogo.category !== 'teste' && (
      <div className="form-check" key={jogo.id}>
        <input
          type="checkbox"
          className="form-check-input"
          id={`jogo-${jogo.id}`}
          checked={jogosSelecionados.includes(jogo.id)}
          onChange={() => handleSelecionarJogo(jogo.id)}
        />
        <label className="form-check-label" htmlFor={`jogo-${jogo.id}`}>
          {jogo.title}
        </label>
      </div>
      )
    ))}
  </div>
)}
          {tipoPerfil === 'adm' && (
            <div style={{ marginTop: '20px' }} className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="liberarPDF"
              checked={liberarPDF}
              onChange={() => setLiberarPDF(!liberarPDF)}
            />

            <label className="form-check-label" htmlFor="liberarPDF">
              Plano Anual - liberar PDF
            </label>
            </div>
          )}  
        

        {tipoPerfil === 'adm' && liberarPDF && (
          <div className="form-floating">
            <input
              type="date"
              className="form-control"
              id="floatingDate"
              placeholder="Data"
              value={dataSelecionada}
              onChange={(e) => setDataSelecionada(e.target.value)}
              defaultValue={dataPadraoFormatada}
            />
            <label htmlFor="floatingDate">Data que serão liberados:</label>
          </div>
        )}

        <div className="text-center">
          <Link to="/app/alunos" className="btn btn-outline-primary btn-acao">
            Voltar
          </Link>
          <button
            onClick={cadastrarUsuario}
            type="button"
            className="btn btn-primary btn-acao"
          >
            Salvar
          </button>
        </div>

        {mensagem.length > 0 ? (
          <div className="alert alert-danger mt-2" role="alert">
            {mensagem}
          </div>
        ) : null}
        {sucesso === 'S' ? <Redirect to="/app/alunos" /> : null}

        <p className="mt-5 mb-3 text-muted">&copy; Desenvolvido por ElSoares</p>
      </form>
    </div>
  );
}

export default NovoAluno;
