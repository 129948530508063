import React, { useContext } from 'react';
import { AuthContext } from '../../Context/auth';
import { Link } from 'react-router-dom';
import { format, isAfter } from 'date-fns'; // Importe as funções necessárias do date-fns
import './listacliente.css';

function ListaClientes(props) {
  const { perfil } = useContext(AuthContext);
  const { tipoPerfil } = useContext(AuthContext);

  function formatarData(data) {
    if (!data) return ''; // Verifica se a data é válida
  
    const partesData = data.split('-');
    if (partesData.length !== 3) return ''; // Verifica se a data possui três partes (ano, mês e dia)
  
    const [ano, mes, dia] = partesData;
    return `${dia}/${mes}/${ano}`;
  }

  return (
    <table className="table table-hover table-bordered">
      <thead>
        <tr className="table-secondary">
          <th scope="col">E-mail</th>
          <th scope="col">{tipoPerfil === 'adm' ? "Liberação PDF" : "Data Expiração"}</th>
          <th scope="col" className="col-acao"></th>
        </tr>
      </thead>
      <tbody>
            {
            props.arrayClientes.map((aluno) => {
                let dateClass = 'blue';
                let dateToShow = '';
                if (tipoPerfil === 'adm') {
                  let pdfDate = '';
                  const isPdfRead = new Date(aluno.perfil) <= new Date();
                  if (aluno.perfil && aluno.perfil != '') {
                    pdfDate = formatarData(aluno.perfil);
                  }
                  dateClass = isPdfRead ? 'blue' : 'red';
                  dateToShow = pdfDate;                
                } else {
                  let dataExpiracao = new Date(aluno.expirationDate);
                  dataExpiracao.setDate(dataExpiracao.getDate() + 1);
                  dataExpiracao.setHours(23, 59, 59, 999); // Define a hora para 23:59:59.999
                  
                  const isExpired = dataExpiracao < new Date();
                  let expirationDate = '';
                  if (aluno.expirationDate && aluno.expirationDate != '') {
                    expirationDate = formatarData(aluno.expirationDate);
                  }
                  dateClass = isExpired ? 'red' : '';
                  dateToShow = expirationDate;
                }

                

                return (
                    <tr key={aluno.id}>
                        <th scope="row">{aluno.email}</th>
                        <th scope="row" className={dateClass}>{dateToShow}</th>
                        <td>
                            <Link to={'/app/editaraluno/' + aluno.id}><i className="fas fa-edit icone-acao"></i></Link>
                            <Link to='#' onClick={() => props.clickDelete(aluno.id, aluno.uidaluno, aluno.email, aluno.senha, aluno.expirationDate)}><i className="far fa-trash-alt icone-acao red"></i></Link>
                        </td>
                    </tr>
                );
            })
        }

      </tbody>
    </table>
  );
}

export default ListaClientes;
