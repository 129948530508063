import React, { useRef, useState, useEffect } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import jogos from '../json/jogos.json';
import "./game.css";

const unityContext = new UnityContext({
  loaderUrl: "../../WebGL/Build/Aviao.loader.js.gz",
  dataUrl: "../../WebGL/Build/Aviao.data.unityweb.gz",
  frameworkUrl: "../../WebGL/Build/Aviao.framework.js.unityweb.gz",
  codeUrl: "../../WebGL/Build/Aviao.wasm.unityweb.gz",
});

function MontaHtml() {
  const unityInstanceRef = useRef(null);
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const jogo = jogos.find(jogo => jogo.name === "aviao");
  const [isMobile, setIsMobile] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    unityContext.on("progress", (progress) => {
      const now = new Date();
      const formattedDate = now.toLocaleString();

      setLoadingProgress(progress);

      if (progress === 1) {
        setTimeout(() => {
          setShowLoading(false);

          setFullScreen(false);
        }, 2000);
      }
    });

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInstructionsClick = () => {
    setModalOpen(true);
  };

  const handleFullscreenClick = () => {
    goFullScreen();
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const goFullScreen = () => {
    setFullScreen(true); // Alterna entre tela cheia e normal
    unityContext.setFullscreen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="App">
      {(isMobile && !showLoading) ? (
        <div
          className="top-bar"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: "40px",            
            backgroundColor: "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          {!showLoading && (
          <button
            onClick={handleBackClick}
            style={{
              marginRight: "10px",
              backgroundColor: "black",
              color: "white",
              border: "none",
            }}
          >
            Voltar
          </button>
          )}
          {!showLoading && (
          <button
            onClick={handleInstructionsClick}
            style={{
              marginRight: "10px",
              backgroundColor: "black",
              color: "white",
              border: "none",
            }}
          >
            Instruções
          </button>
          )}
          {!showLoading && (
          <button
            onClick={handleFullscreenClick}
            style={{
              backgroundColor: "black",
              color: "white",
              border: "none",
            }}
          >
            Tela Cheia
          </button>
          )}
        </div>
      ) : (
        <div
          className="side-bar"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: (isMobile && showLoading) ? "200px" : showLoading ? "100%" : "200px",
            backgroundColor: "black",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            transition: "width 0.5s",
          }}
        >
          {!showLoading && (
          <button
            onClick={handleBackClick}
            style={{
              marginBottom: "10px",
              backgroundColor: "black",
              color: "white",
              border: "none",
            }}
          >
            Voltar
          </button>
          )}
          {!showLoading && (
          <button
            onClick={handleInstructionsClick}
            style={{
              marginBottom: "10px",
              backgroundColor: "black",
              color: "white",
              border: "none",
            }}
          >
            Instruções
          </button>
          )}
          {!showLoading && (
          <button
            onClick={handleFullscreenClick}
            style={{
              backgroundColor: "black",
              color: "white",
              border: "none",
            }}
          >
            Tela Cheia
          </button>
          )}
        </div>
      )}
      {showLoading && (
        <div className="loading-screen">
          <img className="mb-4 logo-img" src="../Images/logo_doreplay.png" alt="" />
          <div className="progress-bar">
            <div className="progress-fill" style={{ width: `${loadingProgress * 100}%` }} />
          </div>
          <p style={{ marginTop: '10px' }}>Carregando o jogo...</p>
        </div>
      )}
      <Unity
        unityContext={unityContext}
        ref={unityInstanceRef}
        style={{
          position: "fixed",
          top: (isMobile && !showLoading) ? "40px" : 0,
          left: isMobile ? 0 : showLoading ? "100%" : "200px",
          width: (showLoading) ? "0%" : isMobile ? "100%" : "calc(100% - 200px)",
          height: (isMobile && !showLoading) ? "calc(100% - 40px)" : "100%",
          border: "1px solid black",
          background: "black",
        }}
      />
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            width: "90%",
            height: "90%",
            maxWidth: "800px",
            maxHeight: "800px",
            margin: "auto",
            marginTop: isMobile ? "2%" : "0%",
            marginBottom: "20%",
            padding: "20px",
            border: "none",
            borderRadius: "5px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#fff",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
        contentLabel="Instruções"
      >
        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        >
          X
        </button>
        <h2>Objetivo</h2>
        <p>{jogo.goal}</p>
        <h2>Regras</h2>
        <p>{jogo.rules}</p>
        {/*<div
          className="video-container"
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/4HhiDg0pFl4"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>*/}
      </Modal>
    </div>
  );
}

export default MontaHtml;
