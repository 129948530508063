import React, {useState} from 'react';

const AuthContext = React.createContext({});

function AuthProvider(props){
    let isLogado = localStorage.getItem("logado");
    let perfilSetado = localStorage.getItem("perfil");
    let tipoPerfilSetado = localStorage.getItem("tipoPerfil");
    let uidProfessor = localStorage.getItem("uidProf");

    const [logado, setLogado] = useState(isLogado === "S" ? true : false);
    const [perfil, setPerfil] = useState(perfilSetado);
    const [tipoPerfil, setTipoPerfil] = useState(tipoPerfilSetado);
    const [uidProf, setUid] = useState(uidProfessor);
    
    return (
        <AuthContext.Provider value={{logado, setLogado, perfil, setPerfil, tipoPerfil, setTipoPerfil, uidProf, setUid}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export {AuthContext, AuthProvider};