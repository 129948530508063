import React from 'react';
import './imagens.css';

function Banner1() {
  return (
    <section id="banner-azul">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h1>Aprender música nunca foi tão interessante, como agora!</h1>
            <h4>Bem-vindo ao nosso universo de aprendizado musical cativante! Nossa plataforma oferece uma experiência única, combinando a magia da música com a diversão dos jogos digitais</h4>
          </div>

          <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
            <img src="Images/principal1.png" alt="ANA SOARES" className="banner-image" />
          </div>
        </div>
      </div>
      <div className="text-center mt-1"> {/* Centralize o botão */}
            <a href="#preco" className="btn btn-lg btn-block buttonContratar">Contratar</a>
        </div>
    </section>
  );
}

export default Banner1;
