import React from 'react';
import Menu from './Components/menu';
import Banner0 from './Components/banner0';
import Banner1 from './Components/banner1';
import Banner2 from './Components/banner2';
import Bonus from './Components/bonus';
import Beneficios from './Components/beneficios';
import Oqueeh from './Components/oqueeh';
import Acesso from './Components/acesso';
import Digital from './Components/digital';
import Quemsou from './Components/quemsou';
import Precos from './Components/precos';
import Footer from './Components/footer';

function Site(){
    return <div>
      <Menu/>
      <Banner0/>
      <Banner1/>
      <Banner2/>
      <Digital/>
      <Bonus/>
      <Beneficios/>
      <Oqueeh/>
      <Acesso/>
      <Precos/>
      <Quemsou/>
      <Footer/>
    </div>;
  }

export default Site;