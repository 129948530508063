import React, { useContext } from 'react';
import {Link} from 'react-router-dom';
import './navbar.css';
import {AuthContext} from '../../Context/auth';

function Navbarjogo(){

    const {setLogado} = useContext(AuthContext);
    const {tipoPerfil} = useContext(AuthContext);

    console.log("menu tipoPerfil="+tipoPerfil);

    function Logout(){
      window.close();
    }

    return <nav className="navbar fixed-top navbar-expand-md navbar-light">

    <div className="container-fluid">
                  
        <a className="navbar-brand" href="/#">
          <img src="/Images/logo_doreplay.png" alt="" height="28" />        
        </a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
            <a href="/app" style={{ color: 'gray' }} onClick={Logout} className="nav-link logout" aria-current="page" >Voltar</a>
            </li>
          </ul>
        </div>    
      
    </div>
  </nav>;
  }

export default Navbarjogo;