import React from 'react';
import Navbar from '../Components/Navbar/navbarjogo';
import Modal from 'react-modal';
import './iniciojogo.css';
import Footer from '../../site/Components/footer';
import jogos from '../json/jogos.json';

// Define o estilo do modal
const modalStyles = {
  content: {
    width: '90%',
    height: '90%',
    maxWidth: '800px',
    maxHeight: '800px',
    margin: 'auto',
    marginTop: '2%',
    marginBottom: '20%',
    padding: '20px',
    border: 'none',
    borderRadius: '5px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  },
};

function InicioJogo(props) {
  const [modalOpen, setModalOpen] = React.useState(false);

  console.log("url_jogo="+props.match.params.id);
  const jogo = jogos.find(jogo => jogo.name === props.match.params.id);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row justify-content-center align-items-center" style={{ height: '70vh' }}>
          <div className="text-center">
            <div>
              <a href={"/app/" + props.match.params.id} className="buttonClass buttonClassRed">JOGAR</a>
            </div>
            <div>
              <button onClick={openModal} className="buttonClass buttonClassGray">Instruções</button>
            </div>            
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Instruções"
      >
        {/* Botão de fechar */}
        <button
          onClick={closeModal}
          style={modalStyles.closeButton}
        >
          X
        </button>

        {/* Conteúdo do modal de instruções */}
        <h2>Objetivo</h2>
        <p>{jogo.goal}</p>
        <h2>Regras</h2>
        <p>{jogo.rules}</p>
        { jogo.idvideo && (
          <div className="video-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <iframe width="560" height="315" src={`https://www.youtube.com/embed/${jogo.idvideo}`} frameBorder="0" allowFullScreen></iframe>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default InicioJogo;
