import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ListaClientes from '../Components/ListaCliente/listacliente';
import './alunos.css';
import firebase from '../Config/firebase';
import 'firebase/firestore';
import { AuthContext } from '../Context/auth';
import SweetAlert from 'react-bootstrap-sweetalert';
import clientesPDF from '../Reports/Clientes/clientes';

function Alunos() {
  const [clientes, setClientes] = useState([]);
  const [busca, setBusca] = useState('');
  const [texto, setTexto] = useState('');
  const [excluido, setExcluido] = useState('');
  const [exclusaoFeita, setExclusaoFeita] = useState(false);
  const [confirmacao, setConfirmacao] = useState(false);
  const [confirmacaoId, setConfirmacaoId] = useState('');
  const [confirmacaoIdAluno, setConfirmacaoIdAluno] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const { uidProf } = useContext(AuthContext);
  const { tipoPerfil } = useContext(AuthContext);
  const [numAlunosCadastrados, setNumAlunosCadastrados] = useState(0); // Adicionado estado para o número de alunos cadastrados
  const [mensagem, setMensagem] = useState('');
  let sufixo = "";
  
  function deleteUserAuth(email, senha) {
    if (!email.includes('@')) {
      sufixo = '@as.com';
    }

    firebase.auth().signInWithEmailAndPassword(email+sufixo, senha)
      .then(() => {
        const userInFirebaseAuth = firebase.auth().currentUser;
        userInFirebaseAuth.delete()
           .then(() => {
            console.log("Usuário excluído com sucesso do Firebase Auth");
            firebase.auth().signOut(); // Desconectar o usuário após a exclusão
          })
          .catch((error) => {
            console.error("Erro ao excluir a conta do Firebase Auth:", error);
          });
        
      });
  }

  function deleteUser(id, uidaluno, email, senha) {
    setExcluido(id);
    setConfirmacao(false);

    firebase.firestore().collection('alunos').doc(id).delete().then(() => {
      setExcluido(id);
      setConfirmacao(false);  
    });

    deleteUserAuth(email, senha);

    setTimeout(() => {
      setExclusaoFeita(true);
    }, 1000); // Atraso de 1 segundo
    
  }

  function confirmDeleteUser(id, uidaluno, email, senha) {
    setConfirmacaoId(id);
    setConfirmacaoIdAluno(uidaluno);
    setEmail(email);
    setSenha(senha);
    setConfirmacao(true);
  }

  useEffect(function () {
    let listaCli = [];

    firebase.firestore().collection('alunos').get().then(async function (resultado) {
      await resultado.docs.forEach(function (doc) {
        const alunoData = doc.data();
        if (alunoData && alunoData.email && alunoData.professor && alunoData.email.indexOf(busca) >= 0 && alunoData.professor.indexOf(uidProf) >= 0) {
          listaCli.push({
            id: doc.id,
            email: alunoData.email,
            senha: alunoData.senha,
            perfil: alunoData.perfil,
            professor: alunoData.professor,
            uidaluno: alunoData.uidaluno,
            expirationDate: alunoData.expirationDate
          });
        }
      });

      setClientes(listaCli);
      setNumAlunosCadastrados(listaCli.length);
    });
  }, [busca, excluido, uidProf, exclusaoFeita]);

  return (
    <div>
      <div className="container-fluid titulo">
      <h1>{tipoPerfil === 'adm' ? 'Cadastro de Professores' : 'Cadastro de Alunos'}</h1>

        <div className="row">
          <div className="col-4">
          <button
              onClick={() => {
                if (tipoPerfil === 'adm' || numAlunosCadastrados < 5) {
                  window.location.href = '/app/novoaluno'; // Redireciona para a página de adicionar aluno
                } else {
                  setMensagem('Não é possível cadastrar mais de 5 alunos!');
                  setTimeout(() => {
                    setMensagem('');
                  }, 2000);
                }
              }}
              className="btn btn-primary btn-cli"
              type="button"
            >
              <i className="fas fa-plus"></i> {tipoPerfil === 'adm' ? 'Professor' : 'Aluno'}
            </button>
            {tipoPerfil === 'adm' ? (
              <button onClick={() => clientesPDF(clientes)} className="btn btn-danger btn-cli" type="button" id="button-addon2">
                <i className="far fa-file-pdf"></i> Gerar PDF
              </button>
            ) : null}
          </div>

          <div className="col-8">
            <div className="input-group mb-3">
              <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" />
              <button onClick={() => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
            </div>
          </div>
        </div>

        <ListaClientes arrayClientes={clientes} clickDelete={confirmDeleteUser} />

        <Link to="/app/home" className="btn btn-outline-primary btn-acao btnc">Voltar</Link>

        {confirmacao ? (
          <SweetAlert
            warning
            showCancel
            showCloseButton
            confirmBtnText="Sim"
            confirmBtnBsStyle="danger"
            cancelBtnText="Não"
            cancelBtnBsStyle="light"
            title="Exclusão"
            onConfirm={() => deleteUser(confirmacaoId, confirmacaoIdAluno, email, senha)}
            onCancel={() => setConfirmacao(false)}
            reverseButtons={true}
          >
            Deseja excluir o aluno selecionado?
          </SweetAlert>
        ) : null}
        
        {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
      </div>
    </div>
  );
}

export default Alunos;
